import React from 'react';
import python from '../css/Python.module.css';
import imgTogle from '../img/list.svg';



const Python = () => {

    return (
        <div>
            <header className={python.header1}>
               <h1 className={python.idi1} id='id1'> Python — что это такое</h1>
                </header>
                
                   <div className='container-fluid' id={python.fluid}>
                       <div className='row'>
                           <div className='col-md-9' id={python.colMd9}>
                           <div className={python.drop}>
                {/* hghfghfghfghfghfgh */}
                <div className={python.dropdown}>
                    <button className={python.dropbtn}>   <img className={python.dropbtnImg} alt=" " src={imgTogle} />  </button>
                    <div className={python.dropdownContent}>
                        <a href='#id1' > Python — что это такое </a>
                        <a href='#id2' > Для чего нужен язык программирования Python </a>
                        <a href='#id3' > История создания </a>
                        <a href='#id4' > Возможности языка </a>
                        <a href='#id5' > Преимущества Python </a>
                        <a href='#id6' > Недостатки Python </a>
                        <a href='#id7' > Где используют Python </a>
                    </div>
                </div>
                {/* hjghjghjgjhggjhgj */}
                </div>




                                <div className={python.textCol}>
                                    <p className={python.text}>Python — это язык программирования, который любят разработчики по всему миру. Его нередко советуют для изучения начинающим специалистам в качестве первого языка. В этой статье мы подробно расскажем о Python: для чего он нужен, где его используют, опишем особенности, преимущества и недостатки данного языка программирования. </p>
                                </div>
                                <div className={python.textDiv}>
                                    <p id='id2' className={python.text1Span}>Для чего нужен язык программирования Python</p>
                                    <p  className={python.text1}>
                                        Python — это активно развивающийся скриптовый язык, который используют для решения большого объема самых разноплановых проблем и задач. Python пригодится в создании компьютерных и мобильных приложений, его применяют в работе с большим объемом информации, при разработке web-сайтов и других разнообразных проектов, используют в машинном обучении. Данный язык программирования используют крупные известные корпорации, такие как Spotify и Амазон (например, для анализа данных и создания алгоритма рекомендаций), YouTube, Инстаграм и даже Walt Disney. Таким образом, Python нашел свое место в различных областях — с его помощью можно решить множество задач разной сложности. 
                                    </p>
                                </div>
                                <div className={python.textDiv}>
                                    <p id='id3' className={python.text1Span}>История создания</p>
                                    <p className={python.text1}>
                                        Разработка языка Python началась в конце восьмидесятых годов двадцатого столетия. Для распределенной операционной системы «Amoeba» понадобился расширяемый скриптовый язык, и сотрудник голландского института Гвидо ван Россум начал писать такой язык в свободное время. Уже в тысяча девятьсот девяносто первом году Гвидо опубликовал первый код. Название языка, несмотря на созвучность с названием семейства неядовитых змей, произошло от другого. Разработчик назвал язык в честь известного британского юмористического телевизионного шоу семидесятых — «Летающий цирк Монти Пайтона». Среди пользователей Python часто называют 
                                    </p>
                                </div>
                            <div className={python.pythonUrlImg}> 
                            <img className={python.imgPython} alt=' ' src='https://mchost.ru/files/img/python.png' />
                            </div>

                            <div className={python.ulliBox}>
                                <p id='id4' className={python.ulliTitle}>Возможности языка</p>
                                <p className={python.title1}>Как мы писали ранее, Python применяют в различных сферах. Разработчики со всего мира полюбили этот язык программирования за множество возможностей. </p>
                                <ul className={python.ulli}>
                                    <li>Объектно-ориентированное программирование (ООП). Реализация ООП в Пайтон хоть и специфична по сравнению с иными объектно-ориентированными языками, но одновременно является неплохо продуманной.</li>
                                    <li>Обобщенное и функциональное программирование.</li>
                                    <li>Модули и пакеты. Программное обеспечение (ПО) на Питоне оформляется в виде модулей, которые могут быть собраны в пакеты.</li>
                                    <li>Интроспекция. Это возможность запросить тип и структуру объекта во время выполнения программы. В Питоне для любого объекта можно узнать всю информацию о его внутренней структуре.</li>
                                    <li>Обработка исключений, итераторы и генераторы.</li>
                                    <li>Управление контекстом выполнения.</li>
                                    <li>Декораторы, регулярные выражения</li>
                                </ul>
                            </div>

                            <div className={python.ulliBox}>
                                <p id='id5' className={python.ulliTitle}>Преимущества Python</p>                                
                                <ul className={python.ulli}>
                                    <li>Язык характеризуется логичным синтаксисом, вследствие чего исходный код программ, написанных «на питоне», легко читается и воспринимается.</li>
                                    <li>Еще одно из преимуществ данного языка программирования — его условная легкость. Он считается наиболее подходящим для начинающих специалистов: разрабатывать несложные программы можно научиться уже спустя пару-тройку дней изучения.</li>
                                    <li>Модули и пакеты. Программное обеспечение (ПО) на Питоне оформляется в виде модулей, которые могут быть собраны в пакеты.</li>
                                    <li>Большое интернет-сообщество. Если разработчик сталкивается с вопросами и трудностями, он всегда может спросить совета у коллег, что значительно ускоряет решение проблем.</li>
                                    <li>Гибкость и масштабируемость. Пайтон позволяет разработчикам адаптировать высокоуровневую логику приложения, что позволяет легко расширять сложные приложения по мере необходимости.</li>
                                    <li>Разработка «на питоне» идет быстрее, чем на большинстве других языках.</li>
                                    <li>Python является интерпретируемым языком программирования. Это значит, что до запуска он представляет собой обычный текстовый файл. Соответственно, программировать можно почти на всех платформах.</li>
                                </ul>
                            </div>

                            <div className={python.ulliBox}>
                                <p id='id6' className={python.ulliTitle}>Недостатки Python</p>
                                <ul className={python.ulli}>
                                    <li>Скорость работы. Высокопроизводительные проекты на чистом Пайтон написать будет затруднительно. Для этого требуется прибегать к помощи других языков.</li>
                                    <li>Безопасность, которая обеспечивается моделью памяти языка Python, сводит на нет большинство возможных процессорных оптимизаций.</li>
                                    <li>Это 2 главных минуса языка программирования Python: его малое быстродействие и недостаточные возможности статического анализа кода. Эти недостатки являются связанными между собой, и решение последней проблемы автоматически откроет двери для устранения первой. </li>
                                </ul>
                            </div>

                            <div className={python.ulliBox}>
                                <p id='id7' className={python.ulliTitle}>Где используют Python</p>
                                <p className={python.title1}>Питон можно использовать в большинстве сфер, примеры которых мы приведем ниже. </p>
                                <ul className={python.ulli}>
                                    <li>Программные приложения. С помощью данного языка программирования можно разрабатывать приложения на компьютер.</li>
                                    <li>Мобильные приложения. Мобильная разработка «на питоне» пользуется спросом во всем мире — для программирования серверной части таких приложений зачастую выбирают Python.</li>
                                    <li>Игры. Множество компьютерных игр целиком или отчасти разработано на Пайтон. Например, он использовался при создании популярной игры World of Tanks.</li>
                                    <li>Встроенные системы. На Питоне можно создавать встроенные системы для разных устройств. Например, для управления банкоматами.</li>
                                    <li>Скрипты. На Python можно написать плагины и скрипты к уже реализованным программам для создания новых модулей и автоматизации процессов.</li>
                                    <li>Машинное обучение (Machine Learning). С его помощью пишут алгоритмы для Machine Learning программ, а также многие аналитические приложения.</li>
                                    <li>Тестирование. Python активно применяется для автоматизации тестирования.</li>
                                </ul>
                            </div>
                            
                            
                           </div>

                           <div className='col-3' id={python.colMd3}>
                                <div className={python.position} >
                                    <ul className={python.hrefUl}>
                                        <li className={python.hrefLi}><a href='#id1' > Python — что это такое </a></li>
                                        <li className={python.hrefLi}><a href='#id2' > Для чего нужен язык программирования Python </a></li>
                                        <li className={python.hrefLi}><a href='#id3' > История создания </a></li>
                                        <li className={python.hrefLi}><a href='#id4' > Возможности языка </a></li> 
                                        <li className={python.hrefLi}><a href='#id5' > Преимущества Python </a></li> 
                                        <li className={python.hrefLi}><a href='#id6' > Недостатки Python </a></li> 
                                        <li className={python.hrefLi}><a href='#id7' > Где используют Python </a></li> 
                                    </ul>
                                </div>
                           </div>
                       </div>
                   </div>
        </div>
    );
};



export default Python;