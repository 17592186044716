import React, { Component } from 'react';
import Navbarr from './js/Navbarr';



export default class App extends Component {

  render() {
    return( 
    <div >

<Navbarr/>

    </div>
    )
  }
}
