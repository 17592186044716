import React from 'react';
import imgTogle from '../img/list.svg';
import php from '../css/PHP.module.css';


const PHP = () => {
    return (
        <div>
            <header className={php.header1}>
               <h1 className={php.idi1} id='id1'>Что такое PHP?</h1>
            </header>
                
                   <div className='container-fluid' id={php.fluid}>
                       <div className='row'>
                           <div className='col-md-9' id={php.colMd9}>
                           <div className={php.drop}>
                {/* hghfghfghfghfghfgh */}
                <div className={php.dropdown}>
                    <button className={php.dropbtn}>   <img className={php.dropbtnImg} alt=" " src={imgTogle} />  </button>
                    <div className={php.dropdownContent}>
                        <a href='#id1' > Что такое PHP? </a>
                        <a href='#id2' > Возможности PHP </a>
                        <a href='#id3' > PHP доступен </a>
                    </div>
                </div>
                {/* hjghjghjgjhggjhgj */}
                </div>



                                <p className={php.text1Span}>Если вы только начинаете знакомиться с PHP, то вам нужно знать определения. Итак, что же такое PHP?</p>
                                <p className={php.text1}> 
                                    PHP – это широко используемый язык сценариев общего назначения с открытым исходным кодом.
                                    Говоря проще, PHP это язык программирования, специально разработанный для написания web-приложений (сценариев), исполняющихся на Web-сервере.
                                    Аббревиатура PHP означает “Hypertext Preprocessor (Препроцессор Гипертекста)". Синтаксис языка берет начало из C, Java и Perl. PHP достаточно прост для изучения. Преимуществом PHP является предоставление web-разработчикам возможности быстрого создания динамически генерируемых web-страниц. Подробнее о преимуществах PHP можно узнать здесь.
                                    Важным преимуществом языка PHP перед такими языками, как языков Perl и C заключается в возможности создания HTML документов с внедренными командами PHP. Подробнее об этой возможность смотрите здесь.
                                    Значительным отличием PHP от какого-либо кода, выполняющегося на стороне клиента, например, JavaScript, является то, что PHP-скрипты выполняются на стороне сервера. Вы даже можете сконфигурировать свой сервер таким образом, чтобы HTML-файлы обрабатывались процессором PHP, так что клиенты даже не смогут узнать, получают ли они обычный HTML-файл или результат выполнения скрипта.
                                    PHP позволяет создавать качественные Web-приложения за очень короткие сроки, получая продукты, легко модифицируемые и поддерживаемые в будущем.
                                    PHP прост для освоения, и вместе с тем способен удовлетворить запросы профессиональных программистов.
                                    Даже если Вы впервые услышали о PHP, изучить этот язык не составит для Вас большого труда. Мы не сомневаемся, что изучив основы PHP в течение нескольких часов, вы уже сможете создавать простые PHP-скрипты.
                                    Язык PHP постоянно совершенствуется, и ему наверняка обеспечено долгое доминирование в области языков web -программирования, по крайней мере, в ближайшее время.</p>
                                <div className={php.textDiv}>
                                    <p id='id2' className={php.text1Span}>Возможности PHP</p>
                                    <p className={php.text1}>
                                    Возможности PHP очень большие. Главным образом, область применения PHP сфокусирована на написание скриптов, работающих на стороне сервера; таким образом, PHP способен выполнять всё то, что выполняет любая другая программа CGI. Например, обрабатывать данных форм, генерировать динамические страницы, отсылать и принимать cookies. Но PHP способен выполнять и множество других задач.<br />
                                    Существуют три основных области, где используется PHP.     
                                    </p>
                                </div>
                           <div className={php.imgContainer}>
                            <img className={php.imgPython} alt=' ' src='https://kinsta.com/wp-content/uploads/2020/03/php-tutorials-1024x512.png' />
                           </div>
                            

                            <div className={php.ulliBox}>
                                <ul className={php.ulli}>
                                    <li>Создание скриптов для выполнения на стороне сервера. PHP наиболее широко используется именно таким образом. Все, что вам понадобится, это парсер PHP (в виде программы CGI или серверного модуля), вебсервер и браузер. Чтобы Вы могли просматривать результаты выполнения PHP-скриптов в браузере, вам нужен работающий вебсервер и установленный PHP. За более подробными сведениями обратитесь к подразделу установка PHP. </li>
                                    <li>Создание скриптов для выполнения в командной строке. Вы можете создать PHP-скрипт, способный запускаться вне зависимости от вебсервера и браузера. Все, что вам потребуется - парсер PHP. Такой способ использования PHP идеально подходит для скриптов, которые должны выполняться регулярно, например, с помощью cron (на платформах *nix или Linux) или с помощью планировщика задач (Task Scheduler) на платформах Windows. Эти скрипты также могут быть использованы в задачах простой обработки текстов. Дополнительная информация находится здесь. </li>
                                    <li>Создание приложений GUI, выполняющихся на стороне клиента. Возможно, PHP является не самым лучшим языком для создания подобных приложений, но, если вы очень хорошо знаете PHP и хотели бы использовать некоторые его возможности в своих клиент-приложениях, вы можете использовать PHP-GTK для создания таких приложений. Подобным образом Вы можете создавать и кросс-платформенные приложения. PHP-GTK является расширением PHP и не поставляется вместе с дистрибутивом PHP. Если вы заинтересованы, посетите сайт PHP-GTK. </li>
                                </ul>
                            </div>  <br />

                            
                           <p id='id3' className={php.text1}>PHP доступен для большинства операционных систем, включая Linux, многие модификации Unix (такие, как HP-UX, Solaris и OpenBSD), Microsoft Windows, Mac OS X, RISC OS, и многих других. (Существует даже версия PHP для OS/2. Неизвестно, правда, насколько соответствующая нынешним реалиям). Также в PHP включена поддержка большинства современных вебсерверов, таких, как Apache, Microsoft Internet Information Server, Personal Web Server, серверов Netscape и iPlanet, сервера Oreilly Website Pro, Caudium, Xitami, OmniHTTPd и многих других. Для большинства серверов PHP поставляется в качестве модуля, для других, поддерживающих стандарт CGI, PHP может функционировать в качестве процессора CGI. </p>
                           <p className={php.text1}>Таким образом, выбирая PHP, вы получаете свободу выбора операционной системы и вебсервера. Кроме того, у вас появляется выбор между использованием процедурного или объектно-ориентированного программирования или же их сочетания. </p>
                           <p className={php.text1}>PHP способен не только выдавать HTML. Возможности PHP включают формирование изображений, файлов PDF и даже роликов Flash (с использованием libswf и Ming), создаваемых "на лету". PHP также способен выдавать любые текстовые данные, такие, как XHTML и другие XML-файлы. PHP способен осуществлять автоматическую генерацию таких файлов и сохранять их в файловой системе вашего сервера, вместо того, чтобы отдавать клиенту, организуя, таким образом, кеш динамического содержания, расположенный на стороне сервера. </p>
                           <img className={php.imgPython} alt=' ' src='https://media.proglib.io/wp-uploads/2018/07/d342e70b000d2961a2932.png' />
                           <p className={php.text1}>Одним из значительных преимуществ PHP является поддержка широкого круга баз данных. Создание скрипта, использующего базы данных, - очень просто. В настоящее время PHP поддерживает следующие базы данных:</p>
                           <table className="table table-bordered border-primary">
                                <tbody style={{fontSize: 17}}>
                                    <tr>
                                    <td>Adabas D</td>
                                    <td>Ingres </td>
                                    <td>Oracle (OCI7 и OCI8) </td>
                                    </tr>
                                    <tr>
                                    <td> dBase </td>
                                    <td>InterBase </td>
                                    <td>Ovrimos </td>
                                    </tr>
                                    <tr>
                                    <td>Empress </td>
                                    <td>FrontBase </td>
                                    <td>PostgreSQL </td>
                                    </tr>
                                    <tr>
                                    <td>FilePro (только чтение) </td>
                                    <td> mSQL </td>
                                    <td> Solid </td>
                                    </tr>
                                    <tr>
                                    <td>Hyperwave </td>
                                    <td>Direct MS-SQL</td>
                                    <td>Sybase </td>
                                    </tr>
                                    <tr>
                                    <td>IBM DB2 </td>
                                    <td>MySQL</td>
                                    <td> Velocis </td>
                                    </tr>
                                    <tr>
                                    <td>Informix </td>
                                    <td>ODBC </td>
                                    <td>Unix dbm </td>
                                    </tr>
                                </tbody>
                                </table>
                                <p className={php.text1}>Также в PHP включена поддержка DBX для работы на абстрактном уровне, так что вы можете работать с любой базой данных, использующих DBX. Кроме того, PHP поддерживает ODBC (Open Database Connection standard), таким образом, вы можете работать с любой базой данных, поддерживающей этот всемирно признанный стандарт. Подробнее о работе PHP с СУБД здесь.</p>
                                <p className={php.text1}>PHP также поддерживает "общение" с другими сервисами с использованием таких протоколов, как LDAP, IMAP, SNMP, NNTP, POP3, HTTP, COM (на платформах Windows) и многих других. Кроме того, вы получаете возможность работать с сетевыми сокетами "напрямую". PHP поддерживает стандарт обмена сложными структурами данных WDDX. Обращая внимание на взаимодействие между различными языками, следует упомянуть о поддержке объектов Java и возможности их использования в качестве объектов PHP. Для доступа к удаленным объектам вы можете использовать расширение CORBA. </p>
                            </div>

                           <div className='col-3' id={php.colMd3}>
                                <div className={php.position} >
                                    <ul className={php.hrefUl}>
                                        <li className={php.hrefLi}><a href='#id1' > Что такое PHP?</a></li>
                                        <li className={php.hrefLi}><a href='#id2' > Возможности PHP </a></li>
                                        <li className={php.hrefLi}><a href='#id3' > PHP доступен </a></li>
                                    </ul>
                                </div>
                           </div>

                       </div>
                   </div>
                   </div>
        
    );
};


export default PHP;